import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import _ from "underscore";
import botAction from "./modules/botAction";
import operatorAction from "./modules/operatorAction";
import search from "./modules/search";
import messagesHandler from "./modules/messagesHandler";
import ticket from "./modules/ticket";
import enquete from "./modules/enquete";
import commonFunctionConst from "@/const/function";
import commonMessageConst from "@/const/message";
import productsFunctionConst from "@product/const/function";
import productsMessageConst from "@product/const/message";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      key: "sAIChatWindow",
      paths: [
        'botAction', 
        'enquete', 
        'messagesHandler', 
        'operatorAction', 
        'search', 
        'ticket'
      ]
    })
  ],
  state: {
    resources: undefined,
    searchingMessage: "",
    constObj: undefined,
    category: undefined
  },
  getters: {
    isInitialState(state) {
      return state.messagesHandler.messages.length == 0;
    }
  },
  mutations: {
    setResources(state, payload) {
      state.resources = payload.resources;
    },
    setConstObj(state, payload) {
      state.constObj = payload.constObj;
    },
    setCategory(state, payload) {
      state.category = payload.category;
    },
    setDefaultMessage(state, payload) {
      state.defaultMessage = payload.defaultMessage;
    }
  },
  actions: {
    async initChatWindow({ commit, state, dispatch }, constObj) {
      console.log("v2.9");
      await dispatch("fetchCategory");
      await dispatch("fetchResources");
      await dispatch("fetchDefaultMessage");
      const obj = Object.assign(
        commonFunctionConst,
        commonMessageConst,
        productsFunctionConst,
        productsMessageConst,
        state.defaultMessage
      );
      commit("setConstObj", { constObj: obj });
      if (this.getters.isInitialState) {
        dispatch("dispatchAction");
      }
      if (obj.FORCE_RESET) {
        commit("clearMessages");
        dispatch("addInitMessage");
      }
    },

    async fetchResources({ commit }) {
      var digits = 4;
      var c = "0123456789";
      var cl = c.length;
      var r = "";
      for (var i = 0; i < digits; i++) {
        r += c[Math.floor(Math.random() * cl)];
      }
      const resourcePath = `${ENV_SETTINGS.resourceFileUrl}?v=${r}`;
      try {
        const result = await axios.get(resourcePath);
        if (result.status !== 200) {
          console.log("Error!!");
          process.exit();
        }
        commit("setResources", { resources: result.data });
      } catch (e) {
        console.log("Error!!");
        process.exit();
      }
    },

    async fetchDefaultMessage({ commit }) {
      var digits = 4;
      var c = "0123456789";
      var cl = c.length;
      var r = "";
      for (var i = 0; i < digits; i++) {
        r += c[Math.floor(Math.random() * cl)];
      }
      const defaultMessagePath = `${ENV_SETTINGS.defaultMessageUrl}?v=${r}`;
      try {
        const result = await axios.get(defaultMessagePath);
        commit("setDefaultMessage", { defaultMessage: result.data });
      } catch (e) {
        console.log("default message not found");
      }
    },

    async fetchCategory({ commit }) {
      var digits = 4;
      var c = "0123456789";
      var cl = c.length;
      var r = "";
      for (var i = 0; i < digits; i++) {
        r += c[Math.floor(Math.random() * cl)];
      }
      const resourcePath = `${ENV_SETTINGS.categoryFileUrl}?v=${r}`;
      try {
        const result = await axios.get(resourcePath);
        commit("setCategory", { category: result.data });
      } catch (e) {
        console.log("category not found");
      }
    },

    async distributeChoiceMessage(
      { commit, state, rootState, dispatch },
      answer
    ) {
      const lastIndex = _.findLastIndex(
        state.messagesHandler.messages,
        m => m.from != "user"
      );
      commit("disabledChoices", { index: lastIndex });
      commit("addMessage", {
        from: "user",
        content: { text: answer.choice }
      });

      if (state.operatorAction.connectStatus === "connected") {
        switch (answer.keyName) {
          case "complete":
            dispatch("sendFeedbackToServer", answer);
            commit("addMessage", {
              from: "bot",
              content: { text: state.constObj.OPERATOR_FEEDBACK_DONE_MESSAGE }
            });
            break;
          case "unsolved":
            dispatch("sendFeedbackToServer", answer);
            commit("addMessage", {
              from: "bot",
              content: { text: state.constObj.OPERATOR_FEEDBACK_ERROR_MESSAGE }
            });
            break;
        }
      } else {
        switch (answer.keyName) {
          case "escalated":
            await dispatch("postTicket", {
              mode: "bot",
              status: "escalated"
            });
            await dispatch("initConnection");
            break;
          case "request_connect":
            dispatch("initConnection");
            break;
          case "request_unconnect":
            dispatch("abortConnection");
            break;
          case "complete":
            dispatch("dispatchAction", answer.keyName);
            if (state.constObj.HAS_RESOLVED_ENQUETE) {
              dispatch("openEnquete", "bot");
            }
            break;
          default:
            dispatch("dispatchAction", answer.keyName);
        }
      }
    },

    async distributeTextMessge({ commit, state, rootState, dispatch }, text) {
      const lastIndex = _.findLastIndex(
        state.messagesHandler.messages,
        m => m.from != "user"
      );
      commit("disabledChoices", { index: lastIndex });
      commit("addMessage", { from: "user", content: { text: text } });
      switch (rootState.operatorAction.connectStatus) {
        case "request":
          const cancellState = { text: text, status: "request" };
          dispatch("cancellMessageToServer", cancellState);
          break;
        case "connected":
          dispatch("sendMessageToServer", { text: text });
          break;
        default:
          const isGreeting = await dispatch("isGreetingMessages", text);
          if (isGreeting && rootState.botAction.stepId === "init") {
            const answer = await dispatch("searchGreetingMessage", text);
            const greetingMessage = {
              from: "bot",
              content: { text: answer },
              status: "init"
            };
            await commit("addMessage", greetingMessage);
            return;
          }
          dispatch("searchMessage", text);
      }
    },

    resetMessages({ commit, state, rootState, dispatch }) {
      if (rootState.operatorAction.connectStatus != "unconnected") {
        dispatch("disConnection");
      }
      const mode =
        rootState.operatorAction.connectStatus != "unconnected"
          ? "operator"
          : "bot";
      dispatch("postTicket", { status: "quit", mode: mode });
      commit("clearMessages");
      dispatch("addInitMessage");
    }
  },
  modules: {
    botAction: botAction,
    search: search,
    operatorAction: operatorAction,
    messagesHandler: messagesHandler,
    ticket: ticket,
    enquete: enquete
  }
});
